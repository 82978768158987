<template>
	<div class="account-box">
		<div class="toggle"></div>
		<div class="form">
			<!-- 账户登录 -->
			<template v-if="!isMsgLogin">
				<form>
					<div class="form-item">
						<div class="input">
							<i class="iconfont icon-user"></i>
							<input v-model="accountField" type="text" placeholder="请输入用户名" />
						</div>
						<div v-if="accountError" class="error">
							<i class="iconfont icon-warning">{{ accountError }}</i>
						</div>
					</div>
					<div class="form-item">
						<div class="input">
							<i class="iconfont icon-lock"></i>
							<input v-model="passwordField" type="password" placeholder="请输入密码" />
						</div>
						<div v-if="passwordError" class="error">
							<i class="iconfont icon-warning">{{ passwordError }}</i>
						</div>
					</div>

					<Button type="submit" class="btn" @click="login()">登 录</Button>
					<div class="form-item">
						<div class="agree">
							<Checkbox v-model="single">
								<span>我已同意</span>
								<a href="https://file.qiludazong.cn/file/5340590935390178.pdf" target="_blank">
									《隐私条款》
								</a>
								<span>和</span>
								<a href="https://file.qiludazong.cn/file/1744249845989842.pdf" target="_blank">
									《服务条款》
								</a>
							</Checkbox>
						</div>
					</div>
				</form>
			</template>
		</div>
		<div class="action">
			<div class="url">
				<!-- forget -->
				<!-- <a href="javascript:" style="margin-left: 170px">忘记密码</a> -->
				<router-link to="/forget" href="javascript:;" style="margin-left: 170px">
					忘记密码
				</router-link>
				<router-link to="/register" href="javascript:;">免费注册</router-link>
			</div>
		</div>
	</div>
</template>
<script>
import Message from '@/components/library/Message'
import JSEncrypt from 'jsencrypt/bin/jsencrypt.min'
import { ref } from 'vue'
export default {
	name: 'LoginForm',
	data () {
		return {
			single: false,
			accountField: '',
			passwordField: '',
		}
	},
	methods: {
		login () {
			const encryptor = new JSEncrypt()
			if (!this.single) {
				Message({ type: 'error', text: '请勾选同意' })
				return
			}
			this.$store
				.dispatch('PublicKey')
				.then((response) => {
					if (response.returnCode === '1') {
						encryptor.setPublicKey(response.result) // 设置公钥
						const postData = ref({
							username: this.accountField,
							password: encryptor.encrypt(this.passwordField),
						})
						this.$store
							.dispatch('UserLogin', postData.value)
							.then((response) => {
								if (response.returnCode === '1') {
									// 跳转到目前地址 or 首页
									this.$router.push('/').then(() => {
										// 登录成功之后的提示信息
										Message({ type: 'success', text: '登录成功' })
									})
								} else {
									//
									Message({ type: 'error', text: '登录超时' })
								}
							})
							.catch(() => { })
					} else {
						Message({ type: 'error', text: '登录超时' })
					}
				})
				.catch(() => { })

		},

	},
	setup () {
		// // 是否为短信登录
	},
}
</script>
<style lang="less" scoped>
.account-box {
	.toggle {
		padding: 15px 40px;
		text-align: right;

		button {
			color: @xtxColor;
			background: none;
			border: none;
			cursor: pointer;

			i {
				font-size: 14px;
			}
		}
	}

	.form {
		padding: 0 40px;

		&-item {
			margin-bottom: 28px;

			.input {
				position: relative;
				height: 36px;

				>i {
					width: 34px;
					height: 34px;
					background: #cfcdcd;
					color: #fff;
					position: absolute;
					left: 1px;
					top: 1px;
					text-align: center;
					line-height: 34px;
					font-size: 18px;
				}

				input {
					padding-left: 44px;
					border: 1px solid #cfcdcd;
					height: 36px;
					line-height: 36px;
					width: 100%;

					&.error {
						border-color: @priceColor;
					}

					&.active,
					&:focus {
						border-color: @xtxColor;
					}
				}

				.code {
					position: absolute;
					right: 1px;
					top: 1px;
					text-align: center;
					line-height: 34px;
					font-size: 14px;
					background: #f5f5f5;
					color: #666;
					width: 90px;
					height: 34px;
					cursor: pointer;
				}

				.code.disabled {
					cursor: wait;
				}
			}

			>.error {
				position: absolute;
				font-size: 12px;
				line-height: 28px;
				color: @priceColor;

				i {
					font-size: 14px;
					margin-right: 2px;
				}
			}
		}

		.agree {
			a {
				color: #069;
			}
		}

		.btn {
			display: block;
			width: 100%;
			height: 40px;
			color: #fff;
			text-align: center;
			line-height: 40px;
			background: @xtxColor;
			border: none;
			font-size: 14px;
			margin-bottom: 20px;

			&.disabled {
				background: #cfcdcd;
			}
		}
	}

	.action {
		padding: 20px 40px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.url {
			a {
				color: #999;
				margin-left: 10px;
			}
		}
	}
}

/deep/ .ivu-checkbox-checked .ivu-checkbox-inner {
	border-color: #d12b23;
	background-color: #d12b23;
}
</style>
