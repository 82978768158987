<template>
  <div>
    <LoginHeader>
      <!-- <div class="login-header"> -->
        您好, 欢迎来到&nbsp;&nbsp; &nbsp; 齐鲁大宗商品交易商城! 请登录
      <!-- </div> -->
    </LoginHeader>
    <section class="login-section">
      <img
        class="imgs"
        src="http://file.qltrade.ixiaoguo.com.cn/file/7249055391122304.png"
      />
      <div class="describe">
        <img src="http://file.qltrade.ixiaoguo.com.cn/file/2209563130959855.png" />
      </div>
      <div class="wrapper">
        <nav>
          <a
            :class="{ active: activeName === 'account' }"
            href="javascript:"
            @click="activeName = 'account'"
          >
            密码登录
          </a>
        </nav>
        <LoginForm v-if="activeName === 'account'" />
      </div>
    </section>
    <LoginFooter />
  </div>
</template>
<script>
import { ref } from 'vue'
import LoginHeader from '@/views/login/components/LoginHeader'
import LoginFooter from '@/views/login/components/LoginFooter'
import LoginForm from '@/views/login/components/LoginForm'

export default {
  name: 'LoginPage',
  components: { LoginForm, LoginFooter, LoginHeader },
  setup () {
    const activeName = ref('account')
    return { activeName }
  },
}
</script>
<style scoped lang="less">
// .login-header {
//   height: 110px;
//   width: 100%;
// 	display: flex;
// 	text-align: center;
// 	justify-content: center;
// }
.login-section {
  // background: url(../../static/login_bg.jpg) no-repeat center / cover;
  // background: url(../../assets/images/login_bg.jpg) no-repeat center / cover;

  .imgs {
    width: 100%;
    height: 600px;
    background-size: cover;
    z-index: -1;
  }
  height: 600px;
  position: relative;
  .describe {
    width: 535px;
    // height: 300px;
    min-height: 250px;
    position: absolute;
    left: 15%;
    top: 120px;
    // background: url(../../static//login_describe.png) no-repeat;
    img {
      // width: 535px;
      min-height: 250px;
      background-size: cover;
      z-index: -1;
    }
  }
  .wrapper {
    width: 380px;
    background: #fff;
    min-height: 400px;
    position: absolute;
    left: 60%;
    top: 84px;
    transform: translate3d(100px, 0, 0);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    .qrcode-box {
      text-align: center;
      padding-top: 40px;
      p {
        margin-top: 20px;
        a {
          color: @xtxColor;
          font-size: 16px;
        }
      }
    }
    nav {
      height: 55px;
      border-bottom: 1px solid #f5f5f5;
      display: flex;
      padding: 0 40px;
      text-align: right;
      align-items: center;
      a {
        flex: 1;
        line-height: 1;
        display: inline-block;
        font-size: 18px;
        position: relative;
        &:first-child {
          border-right: 1px solid #f5f5f5;
          text-align: left;
        }
        &.active {
          // color: @xtxColor;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
